import {Injectable} from '@angular/core';
import {Clinic} from '../../models/clinic.model';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {AUTHENTICATION_STORAGE_KEYS} from '../../../core/authentication/authentication-keys';

export const DEFAULT_CLINIC_KEY = 'defaultClinic';

@Injectable({
	providedIn: 'root'
})
export class ClinicsService {
	private clinics: Array<Clinic>;
	private selectedClinic: Clinic;
	private defaultClinic: Clinic;
	private clinicsSubject: BehaviorSubject<Array<Clinic>> = new BehaviorSubject<Array<Clinic>>(null);
	private selectedClinicSubject: BehaviorSubject<Clinic> = new BehaviorSubject<Clinic>(null);
	private defaultClinicSubject: BehaviorSubject<Clinic> = new BehaviorSubject<Clinic>(null);

	getClinics(): Observable<Array<Clinic>> {
		return this.clinicsSubject.asObservable().pipe(
			filter(clinics => clinics !== null));
	}

	getClinicsWithoutSalons(): Observable<Array<Clinic>> {
		return this.clinicsSubject.asObservable().pipe(
			filter((clinics: Array<Clinic>) => clinics !== null),
			map((clinics: Array<Clinic>) => clinics.filter((clinic: Clinic) => !clinic.isSalon())));
	}

	getClinicsSnapShot(): Array<Clinic> | undefined {
		return this.clinics;
	}

	getClinicsSnapShotWithoutSalons(): Array<Clinic> | undefined {
		return this.clinics.filter((clinic: Clinic) => !clinic.isSalon());
	}

	getClinicByUuid(uuid: string): Observable<Clinic | null> {
		return of(this._getClinicByUuid(uuid));
	}

	getClinicByUuidSnapShot(uuid: string): Clinic | null {
		return this._getClinicByUuid(uuid);
	}

	getSelectedClinic(): Observable<Clinic> {
		if (!this.selectedClinic && this.clinics) {
			this.getDefaultClinic();
			if (this.defaultClinic) {
				this.setSelectedClinic(this.defaultClinic);
			} else {
				const newDefaultClinic = this.clinics[0];
				this.setDefaultClinic(newDefaultClinic); // set first clinic in array as default
				this.setSelectedClinic(newDefaultClinic);
			}
		}
		return this.selectedClinicSubject.asObservable().pipe(
			filter(clinic => clinic !== null));
	}

	getSelectedClinicSnapshot(): Clinic | undefined {
		return this.selectedClinic;
	}

	getDefaultClinic(): Observable<Clinic | null> {
		if (!this.defaultClinic) {
			this.defaultClinic = this.fetchDefaultClinicFromLocalStorage();
			this.defaultClinicSubject.next(this.defaultClinic);
		}
		return this.defaultClinicSubject.asObservable();
	}

	getDefaultClinicSnapshot(): Clinic | undefined {
		return this.defaultClinic;
	}

	getClinicPreferredForOnlineAppointments(): Clinic | undefined {
		return this.clinics.reduce((previousClinic: Clinic, currentClinic: Clinic) =>
			(previousClinic.priorityForOnlineAppointments > currentClinic.priorityForOnlineAppointments) ? previousClinic : currentClinic);
	}

	getAllSalons(): Array<Clinic> {
		return this.clinics.filter((clinic: Clinic) => clinic.isSalon());
	}

	setSelectedClinic(clinic: Clinic): void {
		if (clinic) {
			this.selectedClinic = clinic;
			this.selectedClinicSubject.next(this.selectedClinic);
		}
	}

	setSelectedClinicByUuid(clinicUuid: string): void {
		this.selectedClinic = this._getClinicByUuid(clinicUuid);
		this.selectedClinicSubject.next(this.selectedClinic);
	}

	setDefaultClinic(clinic: Clinic): void {
		if (clinic) {
			if (!this.defaultClinic
				|| (this.defaultClinic && (clinic.uuid !== this.defaultClinic.uuid))) {
				localStorage.setItem(DEFAULT_CLINIC_KEY, JSON.stringify(clinic.uuid));
				this.setSelectedClinic(clinic);
				this.defaultClinic = clinic;
				this.defaultClinicSubject.next(this.defaultClinic);
			}
		}
	}

	setDefaultClinicByUuid(clinicUuid: string): void {
		this.setDefaultClinic(this._getClinicByUuid(clinicUuid));
	}

	setClinics(clinics: Array<Clinic>): void {
		this.clinics = clinics;
		this.clinicsSubject.next(this.clinics);
	}

	clearSelectedClinic(): void {
		this.selectedClinic = null;
	}

	private fetchDefaultClinicFromLocalStorage(): Clinic {
		let clinicUuid = JSON.parse(localStorage.getItem(DEFAULT_CLINIC_KEY));
		if (!clinicUuid) {
			clinicUuid = JSON.parse(localStorage.getItem(AUTHENTICATION_STORAGE_KEYS.storedUser)).defaultClinicUuid;
		}
		return this._getClinicByUuid(clinicUuid);
	}

	private _getClinicByUuid(clinicUuid: string): Clinic | null {
		if (!this.clinics) {
			return null;
		}
		const clinic = this.clinics.find(_clinic => _clinic.uuid === clinicUuid);
		if (!clinic) {
			return null;
		}
		return clinic;
	}
}
